import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

import { whenTablet, whenHalf } from './../utils/responsive';

import theme from './../data/theme';

import resLandscapingPhoto from './../../static/assets/flowerbed_chopped.png';
import comLandscapingPhoto from './../../static/assets/medical_society.jpg';
import snowRemovalPhoto from './../../static/assets/snow_plows.jpg';
import fertilizerPhoto from './../../static/assets/lush_grass.jpg';
import erosionPhoto from './../../static/assets/erosion_control_rotated.jpg';

const PhotoHero = styled.div`
  height: 60rem;
  background-image: linear-gradient(to bottom, hsla(0,0%,0%,0.0), hsla(0, 0%, 0%, 0.20), hsla(0,0%,0%,0.0)), url('/assets/yellowtree.jpg');
  background-size: cover;
  background-position: top;
  text-align: left;
  padding: 10rem 2rem;
  margin: 0 auto;

  ${ whenTablet(css`
    height: 70rem;
    padding: 5rem;
  `) }

  ${ whenHalf(css`
    height: 90rem;
    background-position: 50% 50%;
  `) }
`;

const HeroContentWrapper = styled.div`
  max-width: 120rem;
  margin: 0 auto;
`;

const HeroHeading = styled.h1`
  text-align: left;
  color: ${ theme.colors.white };
  font-size: 3.8rem;
  width: 100%;
  max-width: 45rem;
  min-width: 27.5rem;
  margin: 0rem;
  font-weight: 700;
  line-height: 1.2;
  text-shadow: 1px 3px 15px black;

  ${ whenTablet(css`
    font-size: 4.8rem;
    ${ props => props.first && 'margin-top: 10rem' }
  `) }

  ${ whenHalf(css`
    font-size: 6rem;
    ${ props => props.first && 'margin-top: 20rem' }
  `) }
`;

const HeroButton = styled(Link)`
  text-decoration: none;
  background-color: ${ theme.colors.white };
  color: ${ theme.colors.primary };
  box-shadow: 1px 3px 15px black;
  font-size: 2rem;
  font-weight: 700;
  padding: 1.4rem 2.4rem;
  margin: 0rem 0;
  display: inline-block;

  &:active {
    box-shadow: none;
  }

  ${ whenHalf(css`
    /* display: none; */
  `) }
`;

const Section = styled.section`
  width: 94%;
  max-width: 80rem;
  margin: 0 auto;
  margin-top: 7rem;
`;

const SectionWide = styled(Section)`
  max-width: 120rem;
`;

const SectionHeading = styled.h2`
  font-size: 2.4rem;
  font-weight: 700;
  padding: 2rem 0;
`;

const SectionLead = styled.span`
  font-size: 2.4rem;
  font-weight: 700;
  line-height: calc(1.8rem * 1.8);
`;

const Paragraph = styled.p`
  font-size: 1.8rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
`;

const ServicesCard = styled.div`
  /* box-shadow: 0px 2px 10px 0px hsla(0, 0%, 0%, 0.4); */
  margin-bottom: 5rem;
  display: flex;
  flex-wrap: ${ props => props.reverse ? 'wrap-reverse' : 'wrap' };
`;

const ServicesImage = styled.img`
  display: block;
  height: 30rem;
  width: 100%;
  object-fit: cover;
  object-position: ${ props => props.left ? 'left' : props.right ? 'right' : 'center' } ${ props => props.top ? 'top' : props.bottom ? 'bottom' : 'center' };
  margin: 0;
  flex: 1;
  min-width: 30rem;

  ${ whenTablet(`
    height: 35rem;
  `) }
`;

const cardTextPadding = ['3rem', '2rem'];
const cardTextPaddingTablet = ['4rem', '3rem'];
const cardTextPaddingHalf = ['5rem', '5rem'];

const ServicesCardName = styled.h3`
  font-size: 1.8rem;
  font-weight: 600;
  margin-top: ${ cardTextPadding[0] };
  margin-left: ${ cardTextPadding[1] };

  ${ whenTablet(`
    font-size: 2.0rem;
    margin-top: ${ cardTextPaddingTablet[0] };
    margin-left: ${ cardTextPaddingTablet[1] };
  `) }

${ whenHalf(`
    font-size: 2.4rem;
    margin-top: ${ cardTextPaddingHalf[0] };
    margin-left: ${ cardTextPaddingHalf[1] };
  `) }
`;

const ServicesCardDescription = styled.p`
  font-size: 1.6rem;
  margin: 2rem ${ cardTextPadding[1] };
  line-height: 1.5;

  ${ whenTablet(`
    font-size: 1.8rem;
    margin: 4rem ${ cardTextPaddingTablet[1] };
  `) }

  ${ whenHalf(`
    font-size: 1.9rem;
    margin: 4rem ${ cardTextPaddingHalf[1] };
  `) }
`;

const ServicesCardLink = styled(Link)`
  text-decoration: none;
  color: ${ theme.colors.primary };
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0rem ${ cardTextPadding[1] } ${ cardTextPadding[0] };
  display: inline-block;
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom: 2px solid ${ theme.colors.primary };
  }

  ${ whenTablet(`
    font-size: 1.6rem;
    margin: 0rem ${ cardTextPaddingTablet[1] };
  `) }

  ${ whenHalf(`
    font-size: 1.6rem;
    margin: 0rem ${ cardTextPaddingHalf[1] };
  `) }
`;

const ServicesCardText = styled.div`
  background-color: ${ theme.colors.lightcloud };
  padding: 0;
  flex: 1;
  min-width: 30rem;

  ${ whenTablet(`
    padding: 0rem;
  `) }
`;

const homeKeywords = [
  'Landscaping',
  'Landscaper',
  'Residential Landscaping',
  'Commercial Landscaping',
  'Snow Removal',
  'Lawn Maintenance',
  'Fertilizer',
  'Fertilization',
  'Erosion Control',
  'Erosion',
  'Northeast Ohio',
  'Ohio',
  'Snow Plow',
  'Leaf Removal',
];

const IndexPage = () => (
  <Layout>
    <SEO title='Home' keywords={homeKeywords} />
    <PhotoHero>
      <HeroContentWrapper>
        <HeroHeading first>LANDSCAPING</HeroHeading>
        <HeroHeading>DONE RIGHT</HeroHeading>
        <p css={`
          color: white;
          text-shadow: 0px 3px 15px black;
          text-align: left;
          font-size: 2.8rem;
          font-weight: 500;
          max-width: 45rem;
          margin: 4rem 0;
        `}>Residential and Commercial Landscaping Services in Northeast Ohio</p>
        <HeroButton to="/quote/">Get A Free Quote</HeroButton>
      </HeroContentWrapper>
    </PhotoHero>
    <Section>
      <Paragraph><SectionLead>VLP Landscaping</SectionLead> offers landscape maintenance to residential and commercial customers.  As a full-service lawn and landscaping company, we can maintain your plant, flower, and tree beds; mow, fertilize, and maintain your grass; and offer a number of other services that will keep your landscaping looking its best. </Paragraph>
      <Paragraph>We can handle your complete lawn care services or simply perform special services on an as-needed basis to keep your property looking great throughout the year.</Paragraph>
      <Paragraph>Experienced, uniformed maintenance teams set the standard each day in planting, weed control, pruning, mulching, and mowing.  All gardening and maintenance services are custom-designed to reflect a customer’s tastes and lifestyle.</Paragraph>
    </Section>
    <SectionWide>
      <ServicesCard>
        <ServicesImage src={resLandscapingPhoto} alt="Flowerbed with mulch and precise edging"></ServicesImage>
        <ServicesCardText>
          <ServicesCardName>Residential Landscaping</ServicesCardName>
          <ServicesCardDescription>We'll help you grow and maintain the yard and gardens you've always wanted, and keep your property looking beautiful.</ServicesCardDescription>
          <ServicesCardLink to="/services/residential-landscaping/">Residential Landscaping Services</ServicesCardLink>
        </ServicesCardText>
      </ServicesCard>

      <ServicesCard reverse>
        <ServicesCardText>
          <ServicesCardName>Commercial Landscaping</ServicesCardName>
          <ServicesCardDescription>VLP can maintain and beautify your commercial property to impress your new and existing customers, clients, and tenants.</ServicesCardDescription>
          <ServicesCardLink to="/services/commercial-landscaping/">Commercial Landscaping Services</ServicesCardLink>
        </ServicesCardText>
        <ServicesImage src={comLandscapingPhoto} alt="Green grass lawn and landscaping for commercial property"></ServicesImage>
      </ServicesCard>

      <ServicesCard>
        <ServicesImage top src={snowRemovalPhoto} alt="Three snow plows ready for winter."></ServicesImage>
        <ServicesCardText>
          <ServicesCardName>Snow Removal</ServicesCardName>
          <ServicesCardDescription>Let us take care of the snow and ice for you this winter season. Less worry, more warmth.</ServicesCardDescription>
          <ServicesCardLink to="/services/snow-removal/">Snow Removal Services</ServicesCardLink>
        </ServicesCardText>
      </ServicesCard>

      <ServicesCard reverse>
        <ServicesCardText>
          <ServicesCardName>Fertilization</ServicesCardName>
          <ServicesCardDescription>If your lawn needs some help, we have the solution. VLP Landscaping will evaluate your lawn, and follow a tailored fertilization plan to get it looking its best.</ServicesCardDescription>
          <ServicesCardLink to="/services/fertilization/">Fertilization Services</ServicesCardLink>
        </ServicesCardText>
        <ServicesImage left src={fertilizerPhoto} alt="Beautiful healthy green grass"></ServicesImage>
      </ServicesCard>

      <ServicesCard>
        <ServicesImage src={erosionPhoto} alt="Bank stabilization along edge of a pond."></ServicesImage>
        <ServicesCardText>
          <ServicesCardName>Erosion Control</ServicesCardName>
          <ServicesCardDescription>VLP Landscaping specializes in installing living retaining walls to stabilize and limit erosion.</ServicesCardDescription>
          <ServicesCardLink to="/services/erosion-control/">Erosion Control Services</ServicesCardLink>
        </ServicesCardText>
      </ServicesCard>
    </SectionWide>
  </Layout>
);

export default IndexPage;
